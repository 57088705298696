@import "layout";
body {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  background-color: var(--color-bgwhite-mode);
}
.hero-banner {
  padding: 6rem 0 2rem;
  background-image: url(../assets/img/hherobg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  h1 {
    color: var(--color-white);
    font-size: 63px;
    font-weight: 800;
    text-transform: uppercase;
  }

  @media (max-width: 1199.98px) {
    h1 {
      font-size: 50px;
    }
  }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 40px;
    }
  }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 35px;
    }
  }
  @media (max-width: 575.98px) {
    h1 {
      font-size: 30px;
    }
  }

  .mouse-icon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
    width: 60px;
    margin: 0 auto;
    .nav-link {
      svg {
        animation: mover 1.5s infinite alternate;
      }
      &.active,
      &:hover,
      &:focus,
      &:active {
        svg {
          animation: none;
        }
      }
    }
  }
}
.hwallete-sect {
  background-image: var(--bg-image-mode-wallet-2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .cont-walletbox {
    min-height: 500px;
    display: block;
  }
  @media (max-width: 767.98px) {
    .cont-walletbox {
      min-height: 1px;
      display: block;
      margin-bottom: 1rem;
    }
  }
  .wallet-tab {
    .nav-tabs {
      border-color: #202020;
      position: relative;
      border: 0;
      .nav-item {
        z-index: 1;
        .nav-link {
          margin-bottom: -1px;
          margin-left: 1.5rem;
          font-size: var(--font-size-base);
          border: 0;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          color: var(--color-midblack-mode);
          background-color: var(--color-bgwallet-mode);
          font-weight: 500;
          text-transform: capitalize;
          box-shadow: 0px -3px 8px -2px #5c5c5cc9;
          &.active,
          &:hover,
          &:focus,
          &:active {
            color: var(--color-dark-red);
          }
        }
        &:last-child {
          position: absolute;
          right: 1.5rem;
        }
        @media (max-width: 767.98px) {
          .nav-link {
            font-size: 14px;
          }
          .nav-link {
            margin-left: 0.5rem;
          }
          &:last-child {
            right: 0.5rem;
          }
        }
      }
    }
    .tab-content {
      padding: 1.5rem;
      border-radius: 0.5rem;
      background-color: var(--color-bgwallet-mode);
      box-shadow: 0px 1px 7px -1px #5c5c5cc9;
      .form-label {
        margin-bottom: 0.5rem;
        color: var(--color-white-mode);
        font-size: calc(var(--font-size-base) - 0.125rem);
        font-weight: 400;
        text-transform: capitalize;
        text-align: left;
        display: block;
      }
      .walletdropbutton {
        background-color: #da2b2b12;
        border: 1px solid var(--color-dark-red);
        box-shadow: none;
      }
      .input-group {
        .form-control {
          font-size: 1rem;
          font-weight: 400;
          color: #878787;
          background-color: #da2b2b12;
          border: 1px solid var(--color-dark-red);
          border-radius: 0.25rem;
          box-shadow: none;
        }
        .btn {
          min-width: 120px;
        }
      }
      @media (max-width: 767.98px) {
        .input-group {
          .btn {
            min-width: 70px;
            font-size: 14px;
            padding: 0;
          }
        }
      }
    }
    .walltemag {
      box-shadow: 0px 0px 3px 0px var(--color-yellow);
      padding: .5rem;
    }
  }
}

.hproses-sect {
  background-color: var(--color-bgwhite-mode);
  hr.hpro-divider {
    color: inherit;
    background-color: var(--color-white-mode);
    border: 0;
    opacity: 0.25;
    display: inline-block;
    width: 100px;
    height: 1px;
    margin: 0px 15px;
    vertical-align: middle;
    @media (max-width: 767.98px) {
      display: block;
      width: 1px;
      height: 40px;
      margin: 15px auto;
    }
  }
  .hbgimge-manager {
    background-image: url(../assets/img/simply-bgmanager.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    hr.hpro-divider {
      background-color: var(--color-white-mode);
    }
  }
}

.hcounter-sect {
  // background-color: var(--color-ebony);
  .counter-box {
    padding: 4rem 15px;
    border-right: 1px solid var(--color-inpur-grey);
    text-align: center;
    &:last-child {
      border-right: 0;
    }
  }
  @media (max-width: 767.98px) {
    .counter-box {
      padding: 4rem 15px;
      border-right: 1px solid var(--color-inpur-grey);
      border-bottom: 1px solid var(--color-inpur-grey);
      &:nth-child(2) {
        border-right: 0;
      }
      &:nth-child(3) {
        border-bottom: 0;
      }
      &:last-child {
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
  @media (max-width: 575.98px) {
    .counter-box {
      padding: 3rem 15px;
      border-right: 0;
      border-bottom: 1px solid var(--color-inpur-grey);
      &:nth-child(2) {
        border-right: 0;
      }
      &:nth-child(3) {
        border-bottom: 1px solid var(--color-inpur-grey);
      }
      &:last-child {
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
}

.hlockcoin-sect {
  background-image: var(--bg-image-mode-wallet);
  background-repeat: no-repeat;
  background-size: cover;
}

.hcard-sect {
  .card {
    background-color: var(--color-bggallery-mode);
    border: 0;
    box-shadow: 0px 0px 4px 0px #60606061;
    border-radius: 0;
  }
}

.hcommingsoon-sec {
  margin: 5rem 0 3rem;
  .commingsoonbox {
    background-color: var(--color-bggallery-mode);
    border-radius: 4px;
    position: relative;
    .comsonimgbox {
      position: absolute;
      top: -33%;
      right: 0;
    }
    @media (max-width: 991.98px) {
      .comsonimgbox {
        position: static;
        top: -45%;
        right: 0;
      }
    }
  }
}
.balcheck {
  display: inline-block;
  box-shadow: 0px 0px 6px 1px var(--color-yellow);
  padding: 1rem 1.5rem;
  border-radius: 60px;
  background-color: var(--color-nero);
}

.loginmodal {
  background-color: #000000c9;
  .modal-content {
    background-color: var(--color-ebony-lite);
    box-shadow: 0px 0px 5px -1px var(--color-yellow);
  }
}
@media (max-width: 991.98px) {
  .hcommingsoon-sec {
    margin: 0 0 2.5rem;
  }
}

@media (min-width: 1200px) {
}
