header {
  .navbar {
    transition: all 1.5s;
    background-color: transparent;
    border-bottom: 0;
    .navbar-brand {
      // top: 0;
      color: rgba(0, 0, 0, 0.9);
      background-color: #2f3140;
      padding: 15px 14px 30px;
      // position: absolute;
      // left: auto;
      margin-bottom: -55px;
      border-radius: 0px 0px 60px 60px;
    }
    .navbar-toggler {
      display: none;
    }
    @media (max-width: 767.98px) {
      .navbar-nav {
        padding: 50px 0px 1px 1px;
      }
      .navbar-brand {
        // top: 0;
        padding: 10px 5px 15px;
        // position: absolute;
        // left: auto;
        border-radius: 0px 0px 40px 40px;
        width: 20%;
        margin-bottom: -40px;
      }
    }
    @media (max-width: 575.98px) {
      .navbar-brand {
        width: 19% !important;
        margin-right: 0;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .nav-item.dropdown {
      padding: 0 1rem;
      background-color: transparent;
    }
    .navbar-nav {
      .nav-link,
      .nav-link.disabled {
        color: var(--color-white);
        text-transform: capitalize;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin: 1rem 0.75rem 1rem 0.75rem;
        font-size: var(--font-size-base);
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--color-dark-red);
        }
      }
      .conectwlbtn {
        margin: 1rem 0rem 1rem 0.75rem;
        background-color: var(--color-dark-red);
        padding: 0.5rem 1.5rem 0.5rem !important;
        border-radius: 50px;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #b62126;
          color: var(--color-white-mode);
        }
      }
      .nav-link.dropdown-toggle {
        background-color: transparent;
      }
      .dropdown-menu {
        background-color: var(--color-ebony-lite);
        .dropdown-item {
          color: var(--color-white);
          &:hover,
          &:focus {
            color: var(--color-white);
            background-color: var(--color-dark-red);
          }
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .navbar {
      background-color: var(--color-ebony-lite) !important;
      transition: all 1.5s;
      .navbar-nav {
        .nav-link,
        .nav-link.disabled {
          margin: 1rem 0.75rem 0rem 0rem;
          &:last-child {
            margin: 1rem 0rem 0rem 0rem;
          }
        }
      }
      .Mobile-nav.navbar-nav {
        padding: 0px;
        display: inline-block;
        margin: 0 auto 0;
        display: inline;
        .nav-link,
        .nav-link.disabled {
          display: inline;
          font-size: 13px;
          &:last-child {
            margin: 0rem 0rem 0rem 0rem;
            padding: 0.5rem 0.75rem 0.5rem !important;
            font-size: 13px;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.header-black {
  background-color: var(--color-ebony-lite) !important;
  transition: all 1.5s;
}
.footer {
  background-color: var(--color-black);
  .nav {
    .nav-link {
      color: var(--color-white);
      font-size: calc(var(--font-size-base) - 0.125rem);
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      padding: 0.2rem 0.5rem;
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: var(--color-dark-red);
      }
    }
    .nav-link.disabled {
      margin-bottom: 0.5rem;
      color: var(--color-dark-red);
      font-size: calc(var(--font-size-base) - 0.125rem);
      pointer-events: none;
      cursor: default;
      text-transform: uppercase;
    }
  }
}

.DarkMode {
  // position: fixed;
  // right: 1%;
  // top: 15%;
  // z-index: 1;
  width: 60px;
  margin: 1.7rem 0.5rem;
  .darklitemode {
    margin: 0 auto;
    .checkbox {
      opacity: 0;
      position: absolute;
    }

    .label {
      margin: 0 auto;
      width: 37px;
      height: 19px;
      background-color: #9f0000;
      display: flex;
      border-radius: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 3px;
      position: relative;
      transform: scale(1.5);
      cursor: pointer;
    }

    .ball {
      width: 15px;
      height: 15px;
      background-color: #9f0000;
      border: 3px solid #181c36;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      transition: transform 0.2s linear;
    }

    .checkbox:checked + .label .ball {
      transform: translateX(19px);
    }

    .fa-moon {
      color: #ffc107;
      font-size: 12px;
    }

    .fa-sun {
      color: #ffffff;
      font-size: 12px;
    }
  }

  @media (max-width: 767.98px) {
    .DarkMode {
      margin: 1rem 0.5rem;
    }
    .darklitemode {
      margin: 1.5rem 1rem 1rem 1rem;
    }
    .navbar-toggler {
      display: none;
      padding: 0.2rem 0.5rem;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      transition: box-shadow 0.15s ease-in-out;
      color: black;
      border-color: #ca9c3a;
      background-color: #ca9c3a;
    }
  }
}
@media (max-width: 767.98px) {
  // .DarkMode {
  //   position: fixed;
  //   right: 1.5%;
  //   top: 7%;
  //   z-index: 99;
  //   width: 60px;
  //   margin: 1.7rem 0.5rem;
  // }
}

.pagesection {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li {
    color: var(--color-white-mode);
  }
}

#google_translate_element {
  color: transparent;
  font-size: 0 !important;
  line-height: 0;
  margin: 1rem 0.5rem !important;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  .goog-logo-link {
    display: none !important;
  }

  .goog-te-gadget {
    color: transparent !important;
    font-size: 0 !important;
    .goog-te-combo {
      height: 30.5px !important;
      font-size: 16px !important;
      border-radius: 100% !important;
      padding: 0px !important;
      background-color: var(--color-ebony-lite);
      color: var(--color-grey);
      border: 0;
      vertical-align: middle;
      background-image: url(../assets/img/language.svg);
      background-repeat: no-repeat;
      background-size: 23px;
      background-position: 1px 5px;
      width: 25px;
      transition: all 1.5s;
      cursor: pointer;
      &:focus,
      &:hover,
      &:active,
      &.active {
        border: 0;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}
