html {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-mode: #efefef;
  --color-grey-mode: #919191;
  --color-bgwhite-mode: #0c0e1c;
  --color-bggallery-mode: #000000;
  --color-midblack-mode: #ffffff;
  --bg-image-mode-wallet: url(../assets/img/coin-loc-darkmode.svg);
  --bg-image-mode-wallet-2: url(../assets/img/wallet-bg-lite.svg);
  --color-bgwallet-mode: #0e0e0eb3;
  --color-chart-mode: #282828;
  // End Dark Mode Palletes
  --color-cod-grey: #080808;
  --color-grey: #919191;
  --color-dark-red: #da2b2b;
  --color-blue: #0057ff;
  --color-ebony: #0c0e1c;
  --color-ebony-lite: #181c36;
  --color-gallery: #efefef;
  --color-dark: #212121;
  --color-inpur-grey: #e1e1e1;
  --color-gold: #a97002;
  --color-yellow: #ffc107;
  --color-nero: #100e00;
}
  
html[data-theme="dark"] {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-white-mode: #212121;
  --color-grey: #919191;
  --color-grey-mode: #212121;
  --color-bgwhite-mode: #ffffff;
  --color-bggallery-mode: #efefef;
  --color-midblack-mode: #212121;
  --bg-image-mode-wallet: url(../assets/img/coin-loc-litemode.svg);
  --bg-image-mode-wallet-2: url(../assets/img/wallet-bg-dark.svg);
  --color-bgwallet-mode: #ffffffc2;
  --color-chart-mode: #f5f5f5;
  // End Dark Mode Palletes
  --color-cod-grey: #080808;
  --color-dark-red: #da2b2b;
  --color-blue: #0057ff;
  --color-ebony: #0c0e1c;
  --color-ebony-lite: #181c36;
  --color-gallery: #efefef;
  --color-dark: #212121;
  --color-inpur-grey: #e1e1e1;
  --color-gold: #c6952e;
  --color-yellow: #ffc107;
  --color-nero: #100e00;
}


html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

// constant
.white {
  color: var(--color-white);
}
.color-grey {
  color: var(--color-grey);
}
// change with mode
.white-mode {
  color: var(--color-white-mode);
}
.black {
  color: var(--color-black);
}
.grey-color {
  color: var(--color-grey-mode);
}
.blue {
  color: var(--color-blue-cornflower);
}
.dark-red {
  color: var(--color-dark-red);
}

.gold-color {
  color: var(--color-gold);
}
.chartmode {
  background-color: var(--color-chart-mode);
}
